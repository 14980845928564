import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import Home from './pages/Home/Home';
import Front from './pages/Resume/Front';
import Back from './pages/Resume/Back';

import './App.scss';
import './NavBar.scss';

function App() {
  return (
    <Router>
      <ul className='navbar no-print'>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/front">Front</Link>
        </li>
        <li>
          <Link to="/back">Back</Link>
        </li>
      </ul>

      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/front">
          <Front />
        </Route>
        <Route exact path="/back">
          <Back />
        </Route>
      </Switch>

    </Router>
  );
}


export default App;
