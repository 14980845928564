import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Footer.scss';

class FooterItem extends Component {
  static displayName = 'FooterItem';
  static propTypes = {
    iconClass: PropTypes.string,
    title: PropTypes.array,
    links: PropTypes.bool
  };
  render() {
    const title = this.props.title.map((ele) => {
      if (this.props.links) {
        return (
          <div key={ele}>
            <a href={`http://www.${ele}`}
              target='_blank'
              rel='noopener noreferrer'
              className='footer-anchor'>
              {ele}
            </a>
          </div>
        );
      } else {
        return (
          <div key={ele}>{ele}</div>
        );
      }
    });

    return (
      <div className='footer-item'>
        <div className="footer-icon-circle">
          <div className="footer-icon">
            <i className={this.props.iconClass} aria-hidden="true"></i>
          </div>
        </div>
        <div className='footer-title'>
          {title}
        </div>
      </div>
    );
  }
}

export default FooterItem;
