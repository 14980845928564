import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SpeakingEvents.scss';

class SpeakingEvents extends Component {
  static displayName = 'SpeakingEvents';
  static propTypes = {
    events: PropTypes.array,
    paddingBottom: PropTypes.number
  };
  static defaultProps = {
    events: [],
    paddingBottom: 0
  };
  compareKeys(a, b) {
    return ((a > b) ? -1 : a < b) ? 1 : 0;
  }

  render() {
    const speaking = this.props.events.filter((ele) => {
      return ele.hide === false;
    });

    const speakingSorted = speaking.sort((a,b) => b.talk_order - a.talk_order);

    const speakingEvents = speakingSorted.sort((a,b) => b.talk_order - a.talk_order).map((ele, idx) => {

      const speakingDates = ele.talks.filter((e) => {return e.hide === false}).map((el, idx) => {
        return (
          <div className='con-sub'>
            <span className='con-date'>{el.date}</span>
            <span className='con-location'>{el.location}</span>
          </div>   
        );
      });

      return (
        <li className='event'
          key={idx}>
          <div className='con-text'>
            <span className='con-name'>{ele.conferenceName}</span>
            {speakingDates}
            {/* <a className='con-website'
              href={ele.website}
              target='_blank'
              rel='noopener noreferrer'>
              {ele.website}
            </a> */}
          </div>
        </li>
      )
    });

    return (
      <ul className='speaking-events circle-checkmark'
        style={{'paddingBottom': `${this.props.paddingBottom}px`}}>
        {speakingEvents}
      </ul>
    );
  }
}

export default SpeakingEvents;
