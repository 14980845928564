import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './KeyCertification.scss';

class KeyCertification extends Component {
  static displayName = 'KeyCertification';
  static propTypes = {
    title: PropTypes.string,
    date: PropTypes.string,
    certNum: PropTypes.string,
    subText: PropTypes.string,
    width: PropTypes.string,
    hideSubText: PropTypes.bool,
    paddingBottom: PropTypes.string
  };
  static defaultProps = {
    width: '200',
    hideSubText: false,
    paddingBottom: '0'
  };

  render() {
    return (
      <div className='key-certification'
        style={{'paddingBottom': `${this.props.paddingBottom}px`}}>
        <div className='cert-heading'>
          <div className='key-cert-text'>
            <span className='key-cert-title'>{this.props.title}</span>
            <div className='key-cert-line'>
              <span className='key-cert-date'>{this.props.date}</span>
              <span className='key-cert-num'>{this.props.certNum}</span>
            </div>
            {!this.props.hideSubText && <span className='key-cert-sub-text'>{this.props.subText}</span>}
          </div>
        </div>
      </div>
    );
  }
}

export default KeyCertification;
