import React, { Component } from "react";
import PropTypes from "prop-types";
import DotBar from "./DotBar";
import "./DotGroup.scss";

class DotGroup extends Component {
	static displayName = "DotGroup";
	static propTypes = {
		dotGroup: PropTypes.array,
	};

	render() {
		const dotGroups = this.props.dotGroup.filter((ele) => {
			return ele.hide === false;
		});

		const dotBars = dotGroups.map((ele, indx) => {
			const middleColClass = indx % 3 === 1 ? " middle-col" : "";
			const lastColClass = (indx + 1) % 3 === 0 ? " last-col" : "";

			return (
				<div
					key={ele.title}
					className={`dot-bar${middleColClass}${lastColClass}`}>
					<div className="dot-title">{ele.title}</div>
					<div className="dots">
						<DotBar hideWording={!!indx} numDots={ele.dots} />
					</div>
				</div>
			);
		});

		return (
			<div className="dot-bar-grouping">
				<div className="dot-row">{dotBars}</div>
			</div>
		);
	}
}

export default DotGroup;
