import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './NumberedList.scss';

class SubItem extends Component {
  static displayName = 'SubItem';
  static propTypes = {
    items: PropTypes.array,
    interpunct: PropTypes.bool,
    date: PropTypes.string
  };
  render() {
    const itemsFiltered = this.props.items.filter((ele) => {
      return ele.hide === false;
    });

    const items = itemsFiltered.map((ele, idx) => {
      const subTexts = ele.text.map((el) => {
        return (
          <li className='' key={el}>{el}</li>
        );
      });

      const cls = idx ? ' sub-title-not-first' : ' sub-title-first';
      const dot = this.props.interpunct ? '-dot' : '';
      
      return (
        <div key={ele.title}>
          <span className={`sub-title${cls}${dot}`}>
            <span className={`sub-title-text${dot}`}>{ele.title}</span>
            {ele.extraInfo && <span className='extra-info'>{ele.extraInfo}</span>}
            {ele.descriptor && <span className='descriptor'>{ele.descriptor}</span>}
          </span>
          {this.props.date && <div className='date'>{this.props.date}</div>}
          <ul className='sub-text'>
            {subTexts}
          </ul>
        </div>
      );
    });

    return (
      <div className='sub-item'>
        {items}
      </div>
    );
  }
}

export default SubItem;
