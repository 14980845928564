import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FooterItem from './FooterItem';

import './Footer.scss';

class Footer extends Component {
  static displayName = 'Footer';
  static propTypes = {
    iconClass: PropTypes.string,
    title: PropTypes.string
  };
  render() {
    return (
      <footer>
        <div className='footer-line'>
          <FooterItem title={["adam.t.schaal@gmail.com"]}
            iconClass="fa fa-envelope"/>
          <FooterItem title={["+1.402.630.4979"]}
            iconClass="fa fa-phone"/>
          <FooterItem title={["twitter.com/clevernyyyy"]}
            iconClass="fa fa-twitter"
            links/>
        </div>
        <div className='footer-line'>
          <FooterItem title={["adamschaal.com"]}
            iconClass="fa fa-window-maximize"
            links/>
          <FooterItem title={["linkedin.com/in/adamschaal"]}
            iconClass="fa fa-linkedin"
            links/>
          <FooterItem title={["github.com/clevernyyyy"]}
            iconClass="fa fa-github"
            links/>
        </div>
      </footer>
    );
  }
}

export default Footer;
