import React, { Component } from 'react';
import './Resume.scss';
import '../../App.scss';

import BigHeading from '../../components/BigHeading/BigHeading';
import DetailedBulletList from '../../components/BulletList/DetailedBulletList';
import DotGroup from '../../components/DotGroup/DotGroup';
import Footer from '../../components/Footer/Footer';
import NameHeader from '../../components/NameHeader/NameHeader';
import NumberedList from '../../components/NumberedList/NumberedList';
import SkillsGroup from '../../components/SkillsContainer/SkillsGroup';
import SmallHeading from '../../components/SmallHeading/SmallHeading';
import SideBarNumberedList from '../../components/NumberedList/SideBarNumberedList';
import SpeakingEvents from "../../components/SpeakingEvents/SpeakingEvents";


class Back extends Component {
	render() {
		let awards = require('../../content/awards.json');
		let contributions = require("../../content/contributions.json");
		let education = require("../../content/education.json");
		let hobbies = require('../../content/hobbies.json');
		let leadership = require('../../content/leadership.json');
    let personal = require('../../content/personal.json');
		let speaking = require("../../content/speaking.json");
		let workplace = require('../../content/workplace.json');
		// let contributions = require("../../content/contributions.json");
		
		return (
			<div className='resume'>
        <div className='content'>
          <section className='main'>

						{/* LEADERSHIP EXPERIENCE */}
            {/* <BigHeading iconClass='fa fa-file-text'
              title='Leadership Experience'/>
            <NumberedList headings={leadership}/> */}


						{/* EDUCATION */}
						<BigHeading
							iconClass="fa fa-graduation-cap"
							title="Education"
						/>
						<NumberedList headings={education} />

						{/* HONORS AND AWARDS */}
            <BigHeading iconClass='fa fa-trophy'
              title='Honors and Awards'/>
            <NumberedList headings={awards}/>

						{/* WORKPLACE ENVIRONMENT */}
						{/* <BigHeading iconClass='fa fa-tasks'
              title='Workplace Environment'
              tightHeading/>
            <DotGroup dotGroup={workplace}/> */}

          </section>
          <aside className='sidebar'>
            <NameHeader
              firstName={personal[0].firstName}
              lastName={personal[0].lastName}
              title={personal[0].informalTitle}
            />

						<div className='sidebar-content'>

							{/* SPEAKING EVENTS */}
							<SmallHeading
								iconClass="fa fa-street-view adjust-up"
								title="Speaking Events"
							/>
							<SpeakingEvents events={speaking} />


              {/* SECURITY SKILLS */}
              {/* <SmallHeading iconClass='fa fa-bar-chart-o'
                title='Security Skills'/>
              <SkillsGroup skillBars={skills}/> */}

							{/* INTERESTS AND HOBBIES */}
              <SmallHeading iconClass='fa fa-battery-three-quarters'
                title='Interests & Hobbies'/>
              <DetailedBulletList bulletList={hobbies}
                paddedList />
						</div>
          </aside>
        </div>
        <Footer />
      </div>
		)
	}
}

export default Back;