import React, { Component } from "react";
import "./Resume.scss";
import "../../App.scss";

/* Import Components */
import BigHeading from "../../components/BigHeading/BigHeading";
import BulletList from "../../components/BulletList/BulletList";
// import DetailedBulletList from "../../components/BulletList/DetailedBulletList";
import Footer from "../../components/Footer/Footer";
import KeyCertification from "../../components/KeyCertification/KeyCertification";
import NameHeader from "../../components/NameHeader/NameHeader";
import NumberedList from "../../components/NumberedList/NumberedList";
import SmallHeading from "../../components/SmallHeading/SmallHeading";
import SideBarNumberedList from '../../components/NumberedList/SideBarNumberedList';
import SpeakingEvents from "../../components/SpeakingEvents/SpeakingEvents";

class Front extends Component {
	render() {
		let education = require("../../content/education.json");
		let leadership = require('../../content/leadership.json');
		let personal = require("../../content/personal.json");
		let speaking = require("../../content/speaking.json");
		let techstack = require("../../content/techstack.json");
		let work = require("../../content/work.json");

		return (
			<div className="resume">
				<div className="content">
					<section className="main">
						<BigHeading
							iconClass="fa fa-briefcase"
							title="Work Experience"
						/>
						<NumberedList headings={work} />
					</section>
					<aside className="sidebar">
						<NameHeader
							firstName={personal[0].firstName}
							lastName={personal[0].lastName}
							title={personal[0].informalTitle}
						/>
						<div className="sidebar-content">
							{/* OBJECTIVE */}
							<SmallHeading
								iconClass="fa fa-file-text-o"
								title="Objective"
							/>
							<div className="objective-text">
								{`${personal[0].title} seeking leadership position in a fast-paced and challenging environment, for a world-class company that values the importance of security.`}
							</div>

							{/* KEY CERTIFICATIONS */}
							<SmallHeading
								iconClass="fa fa-university"
								title="Key Certifications"
							/>
							<KeyCertification
								title="Offensive Security Certified Professional (OSCP)"
								date="ISSUED OCT 2016"
								certNum="OS-101-06167"
								subText=""
								paddingBottom="3"
							/>

							{/* LEADERSHIP EXPERIENCE */}
							<SmallHeading iconClass='fa fa-file-text'
								title='Leadership Experience'/>
							<SideBarNumberedList headings={leadership}/>


							{/* TECHNOLOGY STACK */}
							<SmallHeading
								iconClass="fa fa-code"
								title="Tech Stack Experience"
							/>
							<BulletList bulletList={techstack} alpha />

							{/* CONTRIBUTIONS */}
							{/* <SmallHeading
								iconClass="fa fa-code-fork"
								title="Contributions"
							/>
							<DetailedBulletList
								bulletList={contributions}
								paddedList
							/> */}
						</div>
					</aside>
				</div>
				<Footer />
			</div>
		);
	}
}

export default Front;
