import React, { Component } from 'react';
import './Home.scss';

import reactLogo from '../../static/images/react-icon.svg';
import html5Logo from '../../static/images/html5.png';
import css3Logo from '../../static/images/css3.png';
import dockerLogo from '../../static/images/docker-icon.png';
import twitterLogo from '../../static/images/twitter.png';
import gitLogo from '../../static/images/git.png';

class Home extends Component {
	render() {
    let pdf = require('../../static/adamschaal_resume.pdf');

		return (
      <div className='home'>
        <div className='home-content'>
          <div className='home-title'>My Resume Site</div>
          <div className='site-description'>
            <p>Hi, I am Adam Schaal and this is my resume site.</p>
            <p>If you came to check me out for a prospective job, <a href={pdf} target='_blank' rel='noopener noreferrer'>here is my pdf resume</a>.  If you are visiting looking to learn how to make your own resume from code, please read on!</p>
          </div>
          <div className='home-title'>Making Your Own Resume from Code</div>
          <div className='site-description'>
            <p>After years of difficulty getting my resume to look just right in Word, I realized I could just make a resume out of code!  So below is my resume front and back, created from <a href='https://reactjs.org/' target='_blank' rel='noopener noreferrer'>React</a>, <a href='https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5' target='_blank' rel='noopener noreferrer'>HTML5</a>, <a href='https://www.w3.org/Style/CSS/' target='_blank' rel='noopener noreferrer'>CSS3</a>, deployed with <a href='https://www.docker.com/' target='_blank' rel='noopener noreferrer'>Docker</a> and hosted through <a href='https://pages.github.com/' target='_blank' rel='noopener noreferrer'>Github Pages</a>. I have managed to create a perfect 8.5" x 11" print space so I can print with zero margins exactly to a standard US letter or pdf.</p>
            <p>Writing a resume in code makes it so much easier to add a new accomplishment or a new job, etc. Just add the new event to an array of json and the components will auto-generate a new entry! No more struggling with Word documents and imperfect grid systems, everything is pixel perfect. I have even created a tutorial video and repository (shown below) if you would like to create your own.</p>
            <p>And now, here is each page <a href='/front' target='_blank' rel='noopener noreferrer'>front</a> and <a href='/back' target='_blank' rel='noopener noreferrer'>back</a>. If you have questions or need help creating your own, reach out and I'll help when and where I can.</p>
          </div>
          <div className='contact-area'>
            <div className='home-sub-title'>Contact Info</div>
            <div className='contact-logo-line'>
              <a href='https://twitter.com/clevernyyyy' target='_blank' rel='noopener noreferrer'>
                <img className='logo-image' src={twitterLogo} alt='@clevernyyyy' />
              </a>
              <a href='https://github.com/clevernyyyy' target='_blank' rel='noopener noreferrer'>
                <img className='logo-image' src={gitLogo} alt='https://github.com/clevernyyyy' />
              </a>
            </div>
          </div>
          <div className='home-sub-title'>YouTube Tutorial</div>
          <div className='yt-wrap'>
            <div className='yt-vid'>
              <iframe 
                title='Building Resumes and Finding Tech Jobs'
                className='embed-vid' 
                src='https://www.youtube.com/embed/nUsCq37mLWM'
                frameborder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowfullscreen>
              </iframe>
            </div>
          </div>
          <div className='repo-area'>
            <div className='home-sub-title'>GitHub Repository for Demo</div>
            <div className='repo-link'><a href='https://github.com/Labs-Weekly/resume-starter' target='_blank' rel='noopener noreferrer'>https://github.com/Labs-Weekly/resume-starter</a></div>
          </div>
          <div className='tech-stack-area'>
            <div className='home-sub-title'>Tech Stack for this Site</div>
            <div className='tech-logo-line'>
              <a href='https://reactjs.org/' target='_blank' rel='noopener noreferrer'>
                <img className='logo-image' src={reactLogo} alt='ReactJS' />
              </a>
              <a href='https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5' target='_blank' rel='noopener noreferrer'>
                <img className='logo-image' src={html5Logo} alt='HTML5' />
              </a>
              <a href='https://www.w3.org/Style/CSS/' target='_blank' rel='noopener noreferrer'>
                <img className='logo-image' src={css3Logo} alt='CSS3' />
              </a>
              <a href='https://www.docker.com/' target='_blank' rel='noopener noreferrer'>
                <img className='logo-image' src={dockerLogo} alt='Docker' />
              </a>
            </div>
          </div>
        </div>
      </div>
		)
	}
}

export default Home;