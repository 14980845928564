import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SkillsContainer.scss';

class SkillsContainer extends Component {
  static displayName = 'SkillsContainer';
  static propTypes = {
    title: PropTypes.string,
    numBars: PropTypes.number,
    showWording: PropTypes.bool
  };
  getBars(numBars) {
    let retBars = '';
    if (numBars > 0) {
      retBars = (
        <div className="first-shape"
          key={`${this.props.title}-${numBars}`}>
        </div>
      );
      const actualBars = (
        <div className="parallelogram"
          key={numBars}>
        </div>
      );
      for (let i = 0; i < numBars; i++) {
        retBars = [retBars, actualBars];
      }
      if (numBars === 5) {
        retBars = [
          retBars, 
          <div className="last-shape"
            key={`${numBars}-last`}>
          </div>
        ];
      }
    } else {
      retBars = <div key={`${this.props.title}-0`}/>;
    }
    return (
      <div className="border"
        key={`${this.props.title}-border`}>
        {retBars}
      </div>
    );
  };
  render() {
    const bars = this.getBars(this.props.numBars);
    return (
      <div className="skills-container"
        key={this.props.title}>
        <span className="measurement">{this.props.title}</span>
        {bars}
        {this.props.showWording && 
        <div className="word-box">
          <div className="wording first-word">Beginner</div>
          <div className="wording words">Familiar</div>
          <div className="wording words">Proficient</div>
          <div className="wording words">Expert</div>
          <div className="wording words last-word">Master</div>
        </div>}
      </div>
    );
  }
}

export default SkillsContainer;
