import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SubItem from './SubItem';

import './NumberedList.scss';

class SideBarNumberedList extends Component {
  static displayName = 'SideBarNumberedList';
  static propTypes = {
    headings: PropTypes.array
  };
  
  render() {
    const headingsFiltered = this.props.headings.filter((ele) => {
      return ele.hide === false;
    });
    const headings = headingsFiltered.map((ele, idx) => {
      return (
        <li key={idx} className='sidebar-numbered-break'>
          <div className='title-block'>
            <div className='heading'>
              <span className='sidebar-title'>{ele.title}</span>
            </div>
            <SubItem items={ele.subItems} 
                interpunct
                date={ele.date}
            />
          </div>
        </li>
      );
    });

    return (
      <ul className="numbered-headings">
        {headings}
      </ul>
    );
  }
}

export default SideBarNumberedList;
