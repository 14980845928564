import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SmallHeading.scss';

class SmallHeading extends Component {
  static displayName = 'SmallHeading';
  static propTypes = {
    iconClass: PropTypes.string,
    title: PropTypes.string
  };
  render() {
    return (
      <div className="small-headings">
        <div className="sm-icon-circle">
          <div className="sm-icon">
            <i className={this.props.iconClass} aria-hidden="true"></i>
          </div>
        </div>
        <div className="sm-component">
          <span className="sm-text">{this.props.title}</span>
        </div>
      </div>
    );
  }
}

export default SmallHeading;
