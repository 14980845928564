import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './BulletList.scss';

class DetailedBulletList extends Component {
  static displayName = 'DetailedBulletList';
  static propTypes = {
    bulletList: PropTypes.array,
    paddedList: PropTypes.bool,
    bulletType: PropTypes.string
  };
  static defaultProps = {
    bulletType: 'bullet-icon-circle'
  };
  render() {
    const paddedList = this.props.paddedList ? ' padded-list' : '';
    const paddedBottom = this.props.paddedList ? '' : 'detailed-bullet-group';
    
    const bulletList = this.props.bulletList.map((group, idx) => {

      const twoColBulletList = group.bullets.filter((ele) => {
        return ele.twoCol;
      });

      const singleColBulletList = group.bullets.filter((ele) => {
        return ele.twoCol === false;
      });

      const twoColBulletGroup = twoColBulletList.map((ele, idx) => {
        return(
          <li key={idx}
            className={`bullet-block${paddedList}`}>
            <span className={this.props.bulletType}/>
            <span className='title'>{ele.title}</span>
            {ele.date && <span className='date'>{ele.date}</span>}
            {ele.subtext && <div className='subtext'>{ele.subtext}</div>}
          </li>
        );
      });

      const singleColBulletGroup = singleColBulletList.map((ele, idx) => {
        return(
          <li key={idx}
            className={`bullet-block${paddedList}`}>
            <span className={this.props.bulletType}/>
            <span className='title'>{ele.title}</span>
            {ele.date && <span className='date'>{ele.date}</span>}
            {ele.subtext && <div className='subtext'>{ele.subtext}</div>}
          </li>
        );
      });

      return (
        <div key={idx}>
          {group.title && <span className='detailed-bullet-title'>{group.title}</span>}
          <ul className='detailed-bullet-list-two-col two-cols'>
            {twoColBulletGroup}
          </ul>
          <ul className='detailed-bullet-list'>
            {singleColBulletGroup}
          </ul>
        </div>
      );
    });

    return (
      <div className={paddedBottom}>
        {bulletList}
      </div>
    );
  }
}

export default DetailedBulletList;
