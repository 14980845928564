import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SkillsContainer from './SkillsContainer';
import './SkillsContainer.scss';

class SkillsGroup extends Component {
  static displayName = 'SkillsGroup';
  static propTypes = {
    skillBars: PropTypes.array
  };
  render() {
    const skills = this.props.skillBars.filter((ele) => {
      return ele.hide === false;
    });
    const skillGroup = skills.map((ele, idx) => {
      if (idx === 0) {
        return (
          <SkillsContainer title='legend'
            numBars={5}
            showWording
            key={idx}/>
        );
      } else {
        return (
          <SkillsContainer title={ele.title}
            numBars={ele.numBars}
            key={idx}/>
        );
      }
    });

    return (
      <div className="skills-group">
        {skillGroup}
      </div>
    );
  }
}

export default SkillsGroup;
