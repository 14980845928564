import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './BulletList.scss';

class BulletList extends Component {
  static displayName = 'BulletList';
  static propTypes = {
    bulletList: PropTypes.array,
    alpha: PropTypes.bool
  };
  static defaultProps = {
    alpha: false
  };
  compareKeys(a, b) {
    a = a.toLowerCase();
    b = b.toLowerCase();

    return (a < b) ? -1 : (a > b) ? 1 : 0;
  }
  render() {
    let leftColNum = 0;
    const filteredList = this.props.bulletList.filter((ele) => {
      return ele.hide === false;
    });
    const alphaList = filteredList.sort((a, b) => {
      return this.compareKeys(a.name, b.name)
    });
    const bullets = this.props.alpha ? alphaList : this.props.bulletList;
    const colsClass = bullets.length > 5 ? 'two-cols' : 'one-col';
    
    if (bullets.length > 5) {
      leftColNum = Math.ceil(bullets.length / 2);
    }

    const bulletList = bullets.map((ele, idx) => {
      const liClass = idx < leftColNum ? 'li-border' : 'li-padding-left';
      const liPadding = idx < (bullets.length / 2) && idx + 1 > (bullets.length / 2) ? '' : 'li-padding';
      return (
        <li className={`${liClass} ${liPadding}`}
          key={`${ele.name}-${idx}`}>
          <span className='bullet-icon-circle'/>
          {ele.name}
        </li>
      );
    });

    return (
      <ul className={`bullet-list ${colsClass}`}>
        {bulletList}
      </ul>
    );
  }
}

export default BulletList;
